import React from 'react'

import {Link} from 'gatsby'
import {technologyPartnersHeaderKmPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import Media from 'components/UI/Media'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const TechnologyPartnersHeader = ({
  pageTitle,
  topDescription,
  description,
  featuredPartner,
  subtitle,
  subDescription,
  surtitle,
  featuredImage,
  headerPartnerCta,
  headerPartnerCtaLink,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <Section hasPaddingBottom hasVerySmallPadding>
      <Title type="title" variant="h1" hat={surtitle} />
      <Description type="largeDescription">{topDescription}</Description>
      <Title
        hasMarginTop
        variant="h1"
        type="mediumTitle"
        isCentered={!!isMobile}
      >
        {pageTitle}
      </Title>
      <Description type="description">{description}</Description>
      <div className={classes.logoContainer}>
        <Link to={`#/${featuredPartner?.name}`}>
          <Media data={featuredImage} />
          {/* <div className={classes.badge}>
            {`${featuredPartner.partnershipStatusText} Partner`}
          </div> */}
        </Link>

        <div style={{margin: '24px 0px'}}>
          <RoundButton href={headerPartnerCtaLink} arrow isCentered>
            {headerPartnerCta}
          </RoundButton>
        </div>
      </div>
      <Title variant="h2" type="mediumTitle" isCentered={!!isMobile}>
        {subtitle}
      </Title>
      <Description type="description">{subDescription}</Description>
    </Section>
  )
}

TechnologyPartnersHeader.propTypes = technologyPartnersHeaderKmPropTypes

TechnologyPartnersHeader.defaultProps = {
  description: null,
  featuredPartner: null,
  pageTitle: null,
  subDescription: null,
  subtitle: null,
  surtitle: null,
  topDescription: null,
}

export default TechnologyPartnersHeader
