import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(() => ({
  logoContainer: {
    width: 250,
    justifyContent: 'center',
    margin: '100px auto',
  },
  badge: {
    backgroundColor: '#D3A537',
    color: 'white',
    textAlign: 'center',
    padding: 5,
    font: 'camptonBold',
  },
}))

export default useStyles
